import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
//import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {layout: "fullwidth"},
    //beforeEnter: authGuard
    
  },
  {
    path: '/new',
    name: 'NewApplication',
    meta: {layout: "fullwidth"},
    component: () => import(/* webpackChunkName: "applications" */ '../components/NewApplication.vue'),
    //beforeEnter: authGuard
  },
  {
    path: '/view/:id',
    name: 'ViewApplication',
    meta: {layout: "fullwidth"},
    component: () => import(/* webpackChunkName: "applications" */ '../components/ViewApplication.vue'),
    //beforeEnter: authGuard
  },
  {
    path: '/reference/:id',
    name: 'ViewApplicationRef',
    meta: {layout: "fullwidth"},
    component: () => import(/* webpackChunkName: "applications" */ '../components/AddReference.vue'),
    //beforeEnter: authGuard
  },
  
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
